import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";


const AboutV3 = () => {

	const { t, i18n } = useTranslation();

	const videoEl = useRef(null);


	let publicUrl = process.env.PUBLIC_URL + '/'

	const attemptPlay = () => {
		videoEl &&
			videoEl.current &&
			videoEl.current.play().catch(error => {
				console.error("Error attempting to play", error);
			});
	};

	useEffect(() => {
		attemptPlay();
	}, []);

	return <div className="ltn__about-us-area pt-115 pb-100 ">
		<div className="container">
			<div className="row">
				<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-left">
						{/* <img src={publicUrl+"assets/img/about-img.jpg"} alt="About Us Image" />
			          <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
			            <div className="ltn__video-img ltn__animation-pulse1">
			              <img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
			              <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/unq59dJXulE" data-rel="lightcase:myCollection">
			                <i className="fa fa-play" />
			              </a>
			            </div>
			          </div> */}
						<div>
							<video
								style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
								playsInline
								loop
								muted
								controls
								alt="All the devices"
								src={publicUrl + "assets/video/video.mp4"}
								ref={videoEl}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-30">
							{/* <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">About Us</h6> */}
							<h1 className="section-title">{t('homeAboutHead')}</h1>
							{/* <p>A tradition more than 50 years long on the most beautiful part of the Adriatic Coast</p> */}
						</div>
						<div className="ltn__feature-item ltn__feature-item-3">
							{/* <div className="ltn__feature-icon">
			              <span><i className="flaticon-house-4" /></span>
			            </div> */}
							<div className="ltn__feature-info">
								<h4><a /* href="service-details.html" */>{t('homeAboutPar1Head')}</a></h4>
								<p>{t('homeAboutPar1Text')}</p>
							</div>
						</div>
						<div className="ltn__feature-item ltn__feature-item-3">
							{/* <div className="ltn__feature-icon">
			              <span><i className="flaticon-olive-oil" /></span>
			            </div> */}
							<div className="ltn__feature-info">
								<h4><a /* href="service-details.html" */>{t('homeAboutPar2Head')}</a></h4>
								<p>{t('homeAboutPar2Text')}</p>
							</div>
						</div>
						<div className="ltn__feature-item ltn__feature-item-3">
							{/* <div className="ltn__feature-icon">
			              <span><i className="flaticon-maps-and-location" /></span>
			            </div> */}
							<div className="ltn__feature-info">
								<h4><a /* href="service-details.html" */>{t('homeAboutPar3Head')}</a></h4>
								<p>{t('homeAboutPar3Text')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default AboutV3