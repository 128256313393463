import React, { Component } from 'react';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map mb-120">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2912.210034840843!2d17.312818915348124!3d43.12111377914315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134b01ba9f025835%3A0x66c1470d2d9cc988!2sPansion%20Franka%20apartmani!5e0!3m2!1sen!2shr!4v1655201397827!5m2!1sen!2shr" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default Map