import React from 'react';
import Navbar from './global-components/navbar-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';


let publicUrl = process.env.PUBLIC_URL + '/'

const Apartment10 = () => {

    const { t, i18n } = useTranslation();


    return <div>
        <MetaTags>
            <title>Pansion Franka - Apartment10</title>
            <meta name="Pansion Franka - Apartment 10" content="Pansion Franka apartment page" />
            <meta property="og:title" content="Pansion Franka - Apartment 10" />
        </MetaTags>
        <Navbar />
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className="ltn__blog-meta">
                                <ul>
                                    <li className="ltn__blog-category">
                                        <Link className="bg-orange" to="#">{t('forRent')}</Link>
                                    </li>
                                    <li className="ltn__blog-date">
                                        <i className="far fa-calendar-alt" />2 {t('persons')}
                                    </li>
                                </ul>
                            </div>
                            <h1>{t('apartment')} 10</h1>
                            {/*
                    <h4 className="title-2">Apartment Details</h4>   
                    <div className="property-detail-info-list section-bg-1 clearfix mb-60">                          
                    <ul>
                        <li><label>Property ID:</label> <span>HZ29</span></li>
                        <li><label>Home Area: </label> <span>120 sqft</span></li>
                        <li><label>Rooms:</label> <span>7</span></li>
                        <li><label>Baths:</label> <span>2</span></li>
                        <li><label>Year built:</label> <span>1992</span></li>
                    </ul>
                    <ul>
                        <li><label>Lot Area:</label> <span>HZ29 </span></li>
                        <li><label>Lot dimensions:</label> <span>120 sqft</span></li>
                        <li><label>Beds:</label> <span>7</span></li>
                        <li><label>Price:</label> <span>2</span></li>
                        <li><label>Property Status:</label> <span>For Sale</span></li>
                    </ul>
                    </div> */}
                            <h4 className="title-2">{t('amenities')}</h4>
                            <div className="ltn__faq-inner ltn__faq-inner-2 pb-20">
                                <div id="accordion_2">
                                    <div className="card">
                                        <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                                            {t('amenities')}
                                        </h6>
                                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                                            <div className="card-body">
                                                <ul>
                                                    <p><strong>{t('bedroom')}</strong></p>
                                                    <li>
                                                        <label className="checkbox-item">{t('kingsize')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">AC
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>

                                                    <p><strong>{t('livingRoomKitchen')}</strong></p>
                                                    <li>
                                                        <label className="checkbox-item">{t('couch')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('table')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">SAT TV
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">Wi-Fi
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('microwave')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('coffee')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('dishes')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('cooker')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('fridge')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('rags')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <p><strong>{t('bathroom')}</strong></p>
                                                    <li>
                                                        <label className="checkbox-item">{t('shower')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('towels')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('toiletPaper')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('soap')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <p><strong>{t('balcony')} 1,20 x 3,50 m</strong></p>
                                                    <li>
                                                        <label className="checkbox-item">{t('table')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="checkbox-item">{t('linenDryer')}
                                                            <span className="checkmark" />
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="property-detail-feature-list clearfix mb-45">
                                <ul>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>2 King size beds</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Satellite TV</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Seaside Balcony</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Air Conditioning</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Wi-Fi</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Dishwasher</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Coffee Machine</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Laundry Machine</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Vacuum Cleaner</h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="property-detail-feature-list-item">
                                            <i className="flaticon-double-bed" />
                                            <div>
                                                <h6>Oven</h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
                            <h4 className="title-2">{t('gallery')}</h4>
                            <div className="ltn__property-details-gallery mb-30">
                                <div className="row">
                                    <div className="col-md-6">
                                        
                                        <a href={publicUrl + "assets/img/apartments/ap10/9.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/9.jpg"} alt="Image" />
                                        </a>
                                        <a href={publicUrl + "assets/img/apartments/ap10/16.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/16.jpg"} alt="Image" />
                                        </a>
                                        <a href={publicUrl + "assets/img/apartments/ap10/13.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/13.jpg"} alt="Image" />
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                    <a href={publicUrl + "assets/img/apartments/ap10/12.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/12.jpg"} alt="Image" />
                                        </a>
                                        <a href={publicUrl + "assets/img/apartments/ap10/17.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/17.jpg"} alt="Image" />
                                        </a>
                                        <a href={publicUrl + "assets/img/apartments/ap10/15.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/15.jpg"} alt="Image" />
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                    <a href={publicUrl + "assets/img/apartments/ap10/2.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/2.jpg"} alt="Image" />
                                        </a>
                                    <a href={publicUrl + "assets/img/apartments/ap10/1.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/1.jpg"} alt="Image" />
                                        </a>
                                        
                                        <a href={publicUrl + "assets/img/apartments/ap10/14.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/14.jpg"} alt="Image" />
                                        </a>
                                    </div>
                                    <div className="col-md-6">

                                        
                                        <a href={publicUrl + "assets/img/apartments/ap10/10.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/10.jpg"} alt="Image" />
                                        </a>

                                        <a href={publicUrl + "assets/img/apartments/ap10/11.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/11.jpg"} alt="Image" />
                                        </a>
                                        <a href={publicUrl + "assets/img/apartments/ap10/3.jpg"} data-rel="lightcase:myCollection">
                                            <img className="mb-30" src={publicUrl + "assets/img/apartments/ap10/3.jpg"} alt="Image" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h4 className="title-2">{t('apartmentPlan')}</h4>
                            {/* APARTMENTS PLAN AREA START */}
                            <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                                <div className="tab-content">
                                    <div className="tab-pane fade" id="liton_tab_3_1">
                                        <div className="ltn__apartments-tab-content-inner">
                                            {/* <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="apartments-plan-img">
                                                        <img src={publicUrl + "assets/img/apartments/ap1/plan.jpg"} alt="#" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                                        <h2>First Floor</h2>
                                                        <p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
                                                            Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
                                                            eiusmod tempor.Incididunt labore et dolore magna aliqua.
                                                            sed ayd minim veniam.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="tab-pane fade active show" id="liton_tab_3_2">
                                        <div className="ltn__product-tab-content-inner">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="apartments-plan-img">
                                                        <img src={publicUrl + "assets/img/apartments/ap10/plan-crop.jpg"} alt="#" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-5">
                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Second Floor</h2>
                                <p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
                                    Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
                                    eiusmod tempor.Incididunt labore et dolore magna aliqua.
                                    sed ayd minim veniam.</p>
                                </div>
                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="liton_tab_3_3">
                        <div className="ltn__product-tab-content-inner">
                            <div className="row">
                            <div className="col-lg-7">
                                <div className="apartments-plan-img">
                                <img src={publicUrl+"assets/img/others/10.png"} alt="#" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Third Floor</h2>
                                <p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
                                    Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
                                    eiusmod tempor.Incididunt labore et dolore magna aliqua.
                                    sed ayd minim veniam.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div> */}
                                    {/* <div className="tab-pane fade" id="liton_tab_3_4">
                        <div className="ltn__product-tab-content-inner">
                            <div className="row">
                            <div className="col-lg-7">
                                <div className="apartments-plan-img">
                                <img src={publicUrl+"assets/img/others/10.png"} alt="#" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                                <h2>Top Garden</h2>
                                <p>Enimad minim veniam quis nostrud exercitation ullamco laboris.
                                    Lorem ipsum dolor sit amet cons aetetur adipisicing elit sedo
                                    eiusmod tempor.Incididunt labore et dolore magna aliqua.
                                    sed ayd minim veniam.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Apartment10

