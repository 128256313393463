import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import apartmentData from './apartmentData'
import { useTranslation } from "react-i18next";

const ProductSliderV2 = () => {

	const { t, i18n } = useTranslation();

	console.log(apartmentData)

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70" data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}>
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center---">
						<h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">{t('accomodation')}</h6>
						<h1 className="section-title">{t('apartmentSliderText')}</h1>
					</div>
				</div>
			</div>
			<div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
				<>

					{

						apartmentData.map((item) => (
							/*<div>{item.name} {item.persons} {item.image}</div>
							 <ApartmentSlider name={item.name} persons={item.persons} image={item.image}/> */
							<div className="col-lg-4">
								<div className="ltn__search-by-place-item">
									<div className="search-by-place-img">
										<Link to={`/apartment${item.name}`}><img src={publicUrl + "assets/img/apartment-slider/" + item.image + ".jpg"} alt="#" /></Link>
										<div className="search-by-place-badge">
											<ul>
												<li>{item.persons} {t('persons')}</li>
											</ul>
										</div>
									</div>
									<div className="search-by-place-info">
										{/* <h6><Link to="/contact">San Francisco</Link></h6> */}
										<h4><Link to={`/apartment${item.name}`}>{t('apartment')} {item.name}</Link></h4>
										<div className="search-by-place-btn">
											<Link to={`/apartment${item.name}`}>{t('viewApartment')} <i className="flaticon-right-arrow" /></Link>
										</div>
									</div>
								</div>
							</div>

						))
					}
				</>
				{/* 			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                <li>2 Properties</li>
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            <h6><Link to="/contact">San Francisco</Link></h6>
			            <h4><Link to="/product-details">Mission District Area</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                <li>5 Properties</li>
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            <h6><Link to="/contact">New York</Link></h6>
			            <h4><Link to="/product-details">Pacific Heights Area</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/3.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                <li>9 Properties</li>
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            <h6><Link to="/contact">Sedona, Arizona</Link></h6>
			            <h4><Link to="/product-details">Noe Valley Zones</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" /></Link>
			            <div className="search-by-place-badge">
			              <ul>
			                <li>5 Properties</li>
			              </ul>
			            </div>
			          </div>
			          <div className="search-by-place-info">
			            <h6><Link to="/contact">New York</Link></h6>
			            <h4><Link to="/product-details">Pacific Heights Area</Link></h4>
			            <div className="search-by-place-btn">
			              <Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div> */}
				{/*  */}
			</div>
		</div>
	</div>
}


export default ProductSliderV2