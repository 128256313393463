const apartmentData = [
    {
        name: "1",
        image: "ap1-crop",
        persons: "2+3",
        price: "100 €"
    },
    {
        name: "4",
        image: "ap4",
        persons: "2+2",
        price: "60 €"

    },
    {
        name: "6",
        image: "ap6",
        persons: "2",
        price: "30 €"

    },
    {
        name: "7",
        image: "ap7",
        persons: "2+1",
        price: "60 €"

    },
    {
        name: "8",
        image: "ap8",
        persons: "2+1",
        price: "60 €"

    },
    {
        name: "9",
        image: "ap9",
        persons: "2+1",
        price: "50 €"

    },
    {
        name: "10",
        image: "ap10",
        persons: "2",
        price: "40 €"

    },
    {
        name: "11",
        image: "ap11",
        persons: "2",
        price: "60 €"

    },
    {
        name: "12",
        image: "ap12",
        persons: "2+2",
        price: "90 €"

    },
    {
        name: "13",
        image: "ap13",
        persons: "2",
        price: "25 €"

    }
]

export default apartmentData;