import React, {useRef} from 'react';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

const ContactForm = () => {


	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('gmail', 'frankaTemplate', form.current, 'aowLTPaEiV1VRfSoo')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
	  };


	const { t, i18n } = useTranslation();


	let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-message-area mb-120 mb--100">
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="ltn__form-box contact-form-box box-shadow white-bg">
						<h4 className="title-2">{t('reachOut')}</h4>
						<form ref={form} onSubmit={sendEmail} /* id="contact-form" action={publicUrl+"mail.php"} method="post" */>
						<div className="row">
							<div className="col-md-6">
							<div className="input-item input-item-name ltn__custom-icon">
								<input type="text" name="name" placeholder={t('name')} />
							</div>
							</div>
							<div className="col-md-6">
							<div className="input-item input-item-email ltn__custom-icon">
								<input type="email" name="email" placeholder={t('enterEmail')} />
							</div>
							</div>
						</div>
						<div className="input-item input-item-textarea ltn__custom-icon">
							<textarea name="message" placeholder={t('msg')} defaultValue={""} />
						</div>
						<div className="btn-wrapper mt-0">
							<button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">{t('send')}</button>
						</div>
						<p className="form-messege mb-0 mt-20" />
						</form>
					</div>
					</div>
				</div>
				</div>
			</div>
        }

export default ContactForm