import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from "react-i18next";
import MetaTags from 'react-meta-tags';

const About_v1 = () => {

    const { t, i18n } = useTranslation();
    return <div>
                <MetaTags>
            <title>Pansion Franka - About</title>
            <meta name="Pansion Franka - About" content="Pansion Franka about page" />
            <meta property="og:title" content="Pansion Franka - About" />
          </MetaTags>
        <Navbar />
{/*         <PageHeader headertitle={t('aboutUs')} />
 */}        <AboutV4 />
        {/* <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
        {/* <Team /> */}
        {/* <Testimonial /> */}
        {/* <BlogSlider /> */}
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1

