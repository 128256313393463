import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import apartmentData from './section-components/apartmentData';


const  ApartmentGrid = () => {

	const { t, i18n } = useTranslation();

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div>
		<div className="ltn__product-area ltn__product-gutter mb-100">
			<div className="container">
			<h1 className="section-title pt-20 text-center pb-20">{t('accomodation')}</h1>

				<div className="row">
					<div className="col-lg-12">
						<div className="tab-content ">
							<div className="tab-pane fade active show" id="liton_product_grid">
								<div className="ltn__product-tab-content-inner ltn__product-grid-view">
									<div className="row">
										{
											apartmentData.map((item) => (
												<div className="col-lg-4 col-sm-6 col-12">
													<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
														<div className="product-img">
															<Link to={`/apartment${item.name}`}><img src={publicUrl + "assets/img/apartment-slider/" + item.image + ".jpg"} alt="#" /></Link>
														</div>
														<div className="product-info">
															<h2 className="product-title go-top"><Link to={`/apartment${item.name}`}>{t('apartment')} {item.name}</Link></h2>
															<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
																<li><span>{item.persons} </span>
																	{t('persons')}
																</li>
															</ul>
														</div>
														<div className="product-info-bottom">
															<div className="product-price">
																<Link to="/prices">{t('startingFrom')} <span>{item.price}<label>/{t('night')}</label></span></Link>
															</div>
														</div>
													</div>
												</div>
											))
										}
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>



		

		


	</div>

}

export default ApartmentGrid