import React from 'react';
import { useTranslation } from "react-i18next";


const TestimonialV2 = () => {
	const { t, i18n } = useTranslation();


	let publicUrl = process.env.PUBLIC_URL + '/'
	let imagealt = 'image'

	return <div className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-65" data-bs-bg={publicUrl + "assets/img/panorama.jpg"}>
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<div className="section-title-area ltn__section-title-2--- text-center---">
						<h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color">{t('guestTestimonials')}</h6>
						<h1 className="section-title white-color">{t('guestTestimonialsTextPart1')} <br />
							{t('guestTestimonialsTextPart2')}</h1>
					</div>
				</div>
			</div>
			<div className="row ltn__testimonial-slider-6-active slick-arrow-3">
				<div className="col-lg-4">
					<div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
						<div className="ltn__testimoni-info">
							<div className="ltn__testimoni-author-ratting">
								<div className="ltn__testimoni-info-inner">
									{/* <div className="ltn__testimoni-img">
			                  <img src={publicUrl+"assets/img/testimonial/1.jpg"} alt="#" />
			                </div> */}
									<div className="ltn__testimoni-name-designation">
										<h5>Rebeka</h5>
										{/* <label>Selling Agents</label> */}
									</div>
								</div>
								{/* <div className="ltn__testimoni-rating">
			                <div className="product-ratting">
			                  <ul>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                  </ul>
			                </div>
			              </div> */}
							</div>
							<p>
								{t('testimonial1')}</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
						<div className="ltn__testimoni-info">
							<div className="ltn__testimoni-author-ratting">
								<div className="ltn__testimoni-info-inner">
									{/* <div className="ltn__testimoni-img">
			                  <img src={publicUrl+"assets/img/testimonial/2.jpg"} alt="#" />
			                </div> */}
									<div className="ltn__testimoni-name-designation">
										<h5>Christian</h5>
										{/* <label>Selling Agents</label> */}
									</div>
								</div>
								{/* <div className="ltn__testimoni-rating">
			                <div className="product-ratting">
			                  <ul>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                  </ul>
			                </div>
			              </div> */}
							</div>
							<p>

								{t('testimonial2')}</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
						<div className="ltn__testimoni-info">
							<div className="ltn__testimoni-author-ratting">
								<div className="ltn__testimoni-info-inner">
									{/* <div className="ltn__testimoni-img">
			                  <img src={publicUrl+"assets/img/testimonial/3.jpg"} alt="#" />
			                </div> */}
									<div className="ltn__testimoni-name-designation">
										<h5>Jakub</h5>
										{/* <label>Selling Agents</label> */}
									</div>
								</div>
								{/* <div className="ltn__testimoni-rating">
			                <div className="product-ratting">
			                  <ul>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                  </ul>
			                </div>
			              </div> */}
							</div>
							<p>

								{t('testimonial3')}</p>
						</div>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
						<div className="ltn__testimoni-info">
							<div className="ltn__testimoni-author-ratting">
								<div className="ltn__testimoni-info-inner">
									{/* <div className="ltn__testimoni-img">
			                  <img src={publicUrl+"assets/img/testimonial/4.jpg"} alt="#" />
			                </div> */}
									<div className="ltn__testimoni-name-designation">
										<h5>Marta</h5>
										{/* <label>Selling Agents</label> */}
									</div>
								</div>
								{/* <div className="ltn__testimoni-rating">
			                <div className="product-ratting">
			                  <ul>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                    <li><a href="#"><i className="fas fa-star" /></a></li>
			                  </ul>
			                </div>
			              </div> */}
							</div>
							<p>

								{t('testimonial4')}</p>
						</div>
					</div>
				</div>
				{/*  */}
			</div>
		</div>
	</div>

}

export default TestimonialV2