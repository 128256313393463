import React from 'react';
import { useTranslation } from "react-i18next";


const AboutV4 = () => {

	const { t, i18n } = useTranslation();

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top ">
				<div className="container ">
				<div className="row">
				<div className='col-lg-12 w-full align-self-center'>
				{/* <h4 className="title-2">{t('aboutUs')}</h4> */}

					<h1 className="pt-20 section-title text-center">{t('tradition')}<span>.</span></h1>
					</div>
					<div className=" col-lg-6 w-full align-self-center">
					<div className="about-us-img-wrap about-img-left">
						<img src={publicUrl+"assets/img/gallery-v2/obitelj.jpeg"} alt="About Us Image" />
						{/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
						 <div className="ltn__video-img ltn__animation-pulse1">
							<img src={publicUrl+"assets/img/others/8.png"} alt="video popup bg image" />
							<a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
							<i className="fa fa-play" />
							</a>
						</div> 
						</div> */}
					</div>
					</div>
				
					<div className=" col-lg-6 w-full align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20 mt-50">
{/* 						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">{t('aboutUs')}</h6>
 */}						
						<p>{t('aboutUsText')}</p>
						</div>
						{/* <ul className="ltn__list-item-half clearfix">
						<li>
							<i className="flaticon-home-2" />
							A Vacation That You Will Want To Experience Again
						</li>
						<li>
							<i className="flaticon-mountain" />
							Beautiful Scenery
						</li>
						<li>
							<i className="flaticon-heart" />
							Exceptional Lifestyle
						</li>
						<li>
							<i className="flaticon-airplane49" />
							Complete 24/7 Security
						</li>
						</ul> */}
						{/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
						<p>"Enimad minim veniam quis nostrud exercitation <br />
							llamco laboris. Lorem ipsum dolor sit amet" </p>
						</div>
						<div className="btn-wrapper animated">
						<Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
						</div> */}
					</div>
					</div>
				</div>
				</div>
			</div>
        }


export default AboutV4