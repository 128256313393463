import React from 'react';
import { useTranslation } from "react-i18next";

const ContactInfo = () => {


		const { t, i18n } = useTranslation();

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
				<h1 className="section-title pt-20 pb-20 text-center">{t('contactUs')}</h1>

					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" />
						</div>
						<h3>{t('email')}</h3>
						<p>josko.veza@st.t-com.hr</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon Image" />
						</div>
						<h3>{t('phone')}</h3>
						<p>Tel./Fax. +385(0)21 699 276  <br/> GSM +385(0)98 804 010 <br />GSM +385(0)98 735 399</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon Image" />
						</div>
						<h3>{t('address')}</h3>
						<p>Brist, Blatnice 3<br />
						21335 Podaca</p>
					</div>
					</div>
				</div>
				</div>
			</div>
        }

export default ContactInfo