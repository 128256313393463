import React, { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV2 from './components/home-v2';
import About from './components/about';
import Accomodation from './components/accomodation';
import Contact from './components/contact';
import Prices from './components/prices';
import Gallery from './components/gallery';
import Apartment1 from './components/apartment1';
import Apartment4 from './components/apartment4';
import Apartment6 from './components/apartment6';
import Apartment7 from './components/apartment7';
import Apartment8 from './components/apartment8';
import Apartment9 from './components/apartment9';
import Apartment10 from './components/apartment10';
import Apartment11 from './components/apartment11';
import Apartment13 from './components/apartment13';
import Apartment12 from './components/apartment12';


import './i18n';

class Root extends Component {
    render() {
        return (
            <Suspense fallback="loading">
                <HashRouter basename="/">
                    <div>
                        <Switch>
                            <Route exact path="/" component={HomeV2} />
                            <Route path="/prices" component={Prices} />
                            <Route path="/gallery" component={Gallery} />
                            <Route path="/about" component={About} />
                            <Route path="/accomodation" component={Accomodation} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/apartment1" component={Apartment1} />
                            <Route path="/apartment4" component={Apartment4} />
                            <Route path="/apartment6" component={Apartment6} />
                            <Route path="/apartment7" component={Apartment7} />
                            <Route path="/apartment8" component={Apartment8} />
                            <Route path="/apartment9" component={Apartment9} />
                            <Route path="/apartment10" component={Apartment10} />
                            <Route path="/apartment11" component={Apartment11} />
                            <Route path="/apartment12" component={Apartment12} />
                            <Route path="/apartment13" component={Apartment13} />
                        </Switch>
                    </div>
                </HashRouter>
            </Suspense>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('franka'));
