import React from "react";
import { useTranslation } from "react-i18next";

const GalleryGrid = () => {

    let publicUrl = process.env.PUBLIC_URL + '/'

    const { t, i18n } = useTranslation();


    return (
        /*     <div style={{
                paddingBottom: "1000px",
       marginLeft:"100px",
       marginRight:"100px"
            }}>
                <Gallery
                
          images={IMAGES}
          enableLightbox={true}
          enableImageSelection={false}
          // maxRows={3}
         // backdropClosesModal
          // currentImage={3}
          // isOpen={ true}
        />
            </div> */
        <div style={{
           /*  paddingBottom: "1000px", */
            marginLeft: "100px",
            marginRight: "100px"
        }}>
            {/* <h4 className="title-2">{t('gallery')}</h4> */}
            <div className="container">
            <div className="ltn__property-details-gallery mb-30">
            <h1 className="section-title pt-20 pb-20 text-center">{t('gallery')}</h1>
                <div className="row">
                    <div className="col-md-3">
                        <a href={publicUrl + "assets/img/gallery-v2/1.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/1.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/2.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/2.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/3.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/3.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/4.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/4.jpeg"} alt="Image" />
                        </a>
                      

                    </div>
                    <div className="col-md-3">
                        <a href={publicUrl + "assets/img/gallery-v2/5.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/5.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/6.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/6.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/7.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/7.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/8.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/8.jpeg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href={publicUrl + "assets/img/gallery-v2/9.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/9.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/10.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/10.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/11.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/11.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/12.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/12.jpeg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href={publicUrl + "assets/img/gallery-v2/13.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/13.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/14.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/14.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/15.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/15.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/16.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/16.jpeg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href={publicUrl + "assets/img/gallery-v2/17.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/17.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/18.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/18.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/19.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/19.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/20.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/20.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/30.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/30.jpg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href={publicUrl + "assets/img/gallery-v2/21.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/21.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/22.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/22.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/23.jpeg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/23.jpeg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/28.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/28.jpg"} alt="Image" />
                        </a>
                        
                    </div>
                    <div className="col-md-4">
                    <a href={publicUrl + "assets/img/gallery-v2/24.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/24.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/25.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/25.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/26.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/26.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/27.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/27.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/29.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/29.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/49.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/49.jpg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-4">
                    <a href={publicUrl + "assets/img/gallery-v2/31.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/31.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/32.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/32.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/33.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/33.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/34.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/34.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/35.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/35.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/48.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/48.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/50.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/50.jpg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-4">
                    <a href={publicUrl + "assets/img/gallery-v2/36.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/36.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/37.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/37.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/38.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/38.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/39.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/39.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/40.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/40.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/47.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/47.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/51.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/51.jpg"} alt="Image" />
                        </a>
                    </div>
                    <div className="col-md-4">
                    <a href={publicUrl + "assets/img/gallery-v2/41.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/41.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/42.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/42.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/43.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/43.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/44.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/44.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/45.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/45.jpg"} alt="Image" />
                        </a>
                        <a href={publicUrl + "assets/img/gallery-v2/46.jpg"} data-rel="lightcase:myCollection">
                            <img className="mb-30" src={publicUrl + "assets/img/gallery-v2/46.jpg"} alt="Image" />
                        </a>
                        
                       
                    </div>


                </div>
            </div>
            </div>
        </div>
    )
}

export default GalleryGrid;